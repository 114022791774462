import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
// import reportWebVitals from "./reportWebVitals";
import theme from "./components/themes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// TODO - if wanting to send metrics to GA etc.
// https://create-react-app.dev/docs/measuring-performance/
// https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
// reportWebVitals(console.log);
